/**
 * ID: bh-global-css
 * Name: global.scss
 * Description: App-specific global themes. Applied after base and canned stylesheets applied.
 * Version: 4
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Adjust cta-button style
 * 2021-07-27 - MW - v3: Added missing alert danger class
 * 2022-05-25 - MW - v4: Split global.scss into base.all.scss, base.desktop.scss, base.mobile.scss, canned.scss
 */

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* BH-Starter CSS utils */
@import "theme/canned.scss";
@import "theme/base.all.scss";
@import "theme/base.mobile.scss";
@import "theme/base.desktop.scss";

.wide-modal {
  &::part(content) {
    width: 75%;
    height: 95%;
    max-width: 1100px;
  }
}

.settings-modal {
  &::part(content) {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 740px;
    border-radius: 20px;
  }
}

.settings-modal {
  &.super-wide .modal-wrapper {
    max-width: 800px !important;
  }

  &.fullscreen .modal-wrapper {
    max-width: unset !important;
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 740px;
  }

  &.floating {
    .modal-wrapper {
      width: 95%;
      height: 95%;
    }
  }
}


// .settings-modal {
//   backdrop-filter: blur(4px);

//   &.floating {
//     .modal-wrapper {
//       background: transparent;
//       box-shadow: none;
//     }
//   }
// }
